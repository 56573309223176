<template>
  <div>
    <!-- 消息条 -->
    <v-snackbar :timeout="1500" class="mt-4" v-model="config.alert" top transition="slide-y-transition"
      :color="config.alert_type" center text style="top: 56px">
      {{ config.alert_text }}
    </v-snackbar>
    <v-card class="pt-9 mx-auto pa-7" style="max-width: 500px; top: 80px">
      <v-form ref="form" lazy-validation>
        <div class="my-7">
          <div class="text-h4 text-center">登录</div>
        </div>

        <v-text-field v-model="email" :disabled="disable_input" label="邮箱地址" prepend-inner-icon="mdi-email"
          outlined></v-text-field>
        <!-- 密码框 -->
        <v-text-field v-show="password_or_code == 0" v-model="password" type="password"
          :disabled="disable_input || password_or_code == 1" prepend-inner-icon="mdi-key" label="密码" outlined></v-text-field>
        <!-- 密码框 -->
        <!-- 邮箱验证码 -->
        <v-row v-show="password_or_code == 1">
          <v-col>
            <v-text-field outlined v-model="email_code" :disabled="disable_input || password_or_code == 0" label="邮箱验证码"
              :readonly="readonly_input"></v-text-field>
          </v-col>
          <v-col>
            <v-btn height="56px" depressed @click="captcha_dialog.text = ''; do_send_email_code()"
              :disabled="send_btn.disabled" :loading="send_btn.loading">{{ send_btn.text }}</v-btn>
          </v-col>
        </v-row>
        <!-- 邮箱验证码 -->
        <v-row v-if="captcha.enabled" class="mx-1">
          <v-img :src="captcha.img" max-width="210px" max-height="70px" @click="getCaptcha"></v-img>
          <v-text-field class="ma-2" v-model="captcha.input" :disabled="disable_input" label="验证码"
            outlined></v-text-field>
        </v-row>

        <v-btn block :disabled="login_btn_disabled" :loading="disable_input" @click="submit()" depressed large
          color="primary" class="my-2">
          登录
        </v-btn>
      </v-form>

      <p class="text-caption mt-3">
        还没有账号？<router-link to="/reg">注册一个</router-link><br /><router-link to="/forget">忘记密码了？</router-link>
      </p>

      <v-divider></v-divider>
      <v-row no-gutters class="ma-3" justify="center">
        <!-- 切换邮箱/密码登录 -->
        <v-btn class="ma-2" @click="switch_password_or_code(1)" outlined color="#e0620d" v-if="password_or_code == 0">
          <svg t="1706975028932" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="11702" width="20" height="20">
            <path
              d="M511.983115 732.726124 320.004733 575.058175 0.020978 858.878289l1023.958044 0L703.993221 575.058175 511.983115 732.726124zM1023.977999 795.823184 1023.977999 322.789661 735.978702 543.524994 1023.977999 795.823184zM0.020978 322.789661l0 473.03557 288.00032-252.299213L0.020978 322.789661zM0.020978 165.120688l0 94.600565 511.963161 409.93851 511.994883-409.93851L1023.979022 165.120688 0.020978 165.120688z"
              p-id="11703" fill="#e0620d"></path>
          </svg>
        </v-btn>
        <v-btn class="ma-2" @click="switch_password_or_code(0)" outlined color="#707070" v-if="password_or_code == 1">
          <svg t="1706974775107" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="4252" width="20" height="20">
            <path
              d="M443.25546667 486.74133333c-29.35466667-47.104-41.09653333-99.9424-41.09653334-158.72A291.0208 291.0208 0 0 1 695.97866667 34.13333333 291.0208 291.0208 0 0 1 989.86666667 328.02133333a291.0208 291.0208 0 0 1-293.888 293.81973334c-64.64853333 0-117.48693333-17.6128-170.3936-52.90666667l-135.168 135.168 64.64853333 64.7168-82.3296 82.26133333-64.64853333-64.64853333-41.09653334 41.1648 64.64853334 64.64853333-82.3296 82.26133334L102.4 827.5968l340.85546667-340.92373333z m252.7232-335.0528c-99.87413333 0-176.26453333 76.45866667-176.26453334 176.3328s76.3904 176.26453333 176.26453334 176.26453334c99.9424 0 176.3328-76.3904 176.3328-176.26453334 0-99.9424-82.26133333-176.3328-176.3328-176.3328z"
              fill="#707070" p-id="4253"></path>
          </svg>
        </v-btn>
        <!-- 0为密码，1为验证码 -->
        <!-- 切换邮箱/密码登录 -->

        <v-btn class="ma-2" @click="get_tg_url" outlined color="#1296DB">
          <svg t="1682947411636" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="2643" width="20" height="20">
            <path
              d="M679.424 746.862l84.005-395.996c7.424-34.852-12.581-48.567-35.438-40.009L234.277 501.138c-33.72 13.13-33.134 32-5.706 40.558l126.282 39.424 293.156-184.576c13.714-9.143 26.295-3.986 16.018 5.157L426.898 615.973l-9.143 130.304c13.13 0 18.871-5.706 25.71-12.581l61.696-59.429 128 94.282c23.442 13.129 40.01 6.29 46.3-21.724zM1024 512c0 282.843-229.157 512-512 512S0 794.843 0 512 229.157 0 512 0s512 229.157 512 512z"
              fill="#1296DB" p-id="2644"></path>
          </svg>
        </v-btn>
        <v-btn class="ma-2" outlined href="/auth/google" color="#8a8a8a">
          <svg t="1656592905871" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="4360" width="20" height="20">
            <path
              d="M214.101333 512c0-32.512 5.546667-63.701333 15.36-92.928L57.173333 290.218667A491.861333 491.861333 0 0 0 4.693333 512c0 79.701333 18.858667 154.88 52.394667 221.610667l172.202667-129.066667A290.56 290.56 0 0 1 214.101333 512"
              fill="#FBBC05" p-id="4361"></path>
            <path
              d="M516.693333 216.192c72.106667 0 137.258667 25.002667 188.458667 65.962667L854.101333 136.533333C763.349333 59.178667 646.997333 11.392 516.693333 11.392c-202.325333 0-376.234667 113.28-459.52 278.826667l172.373334 128.853333c39.68-118.016 152.832-202.88 287.146666-202.88"
              fill="#EA4335" p-id="4362"></path>
            <path
              d="M516.693333 807.808c-134.357333 0-247.509333-84.864-287.232-202.88l-172.288 128.853333c83.242667 165.546667 257.152 278.826667 459.52 278.826667 124.842667 0 244.053333-43.392 333.568-124.757333l-163.584-123.818667c-46.122667 28.458667-104.234667 43.776-170.026666 43.776"
              fill="#34A853" p-id="4363"></path>
            <path
              d="M1005.397333 512c0-29.568-4.693333-61.44-11.648-91.008H516.650667V614.4h274.602666c-13.696 65.962667-51.072 116.650667-104.533333 149.632l163.541333 123.818667c93.994667-85.418667 155.136-212.650667 155.136-375.850667"
              fill="#4285F4" p-id="4364"></path>
          </svg>
        </v-btn>
        <v-btn class="ma-2" outlined @click="wx_login" color="#0BA35E">
          <svg t="1656592785801" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="3235" width="25" height="25">
            <path
              d="M664.250054 368.541681c10.015098 0 19.892049 0.732687 29.67281 1.795902-26.647917-122.810047-159.358451-214.077703-310.826188-214.077703-169.353083 0-308.085774 114.232694-308.085774 259.274068 0 83.708494 46.165436 152.460344 123.281791 205.78483l-30.80868 91.730191 107.688651-53.455469c38.558178 7.53665 69.459978 15.308661 107.924012 15.308661 9.66308 0 19.230993-0.470721 28.752858-1.225921-6.025227-20.36584-9.521864-41.723264-9.521864-63.862493C402.328693 476.632491 517.908058 368.541681 664.250054 368.541681zM498.62897 285.87389c23.200398 0 38.557154 15.120372 38.557154 38.061874 0 22.846334-15.356756 38.156018-38.557154 38.156018-23.107277 0-46.260603-15.309684-46.260603-38.156018C452.368366 300.994262 475.522716 285.87389 498.62897 285.87389zM283.016307 362.090758c-23.107277 0-46.402843-15.309684-46.402843-38.156018 0-22.941502 23.295566-38.061874 46.402843-38.061874 23.081695 0 38.46301 15.120372 38.46301 38.061874C321.479317 346.782098 306.098002 362.090758 283.016307 362.090758zM945.448458 606.151333c0-121.888048-123.258255-221.236753-261.683954-221.236753-146.57838 0-262.015505 99.348706-262.015505 221.236753 0 122.06508 115.437126 221.200938 262.015505 221.200938 30.66644 0 61.617359-7.609305 92.423993-15.262612l84.513836 45.786813-23.178909-76.17082C899.379213 735.776599 945.448458 674.90216 945.448458 606.151333zM598.803483 567.994292c-15.332197 0-30.807656-15.096836-30.807656-30.501688 0-15.190981 15.47546-30.477129 30.807656-30.477129 23.295566 0 38.558178 15.286148 38.558178 30.477129C637.361661 552.897456 622.099049 567.994292 598.803483 567.994292zM768.25071 567.994292c-15.213493 0-30.594809-15.096836-30.594809-30.501688 0-15.190981 15.381315-30.477129 30.594809-30.477129 23.107277 0 38.558178 15.286148 38.558178 30.477129C806.808888 552.897456 791.357987 567.994292 768.25071 567.994292z"
              p-id="3236" fill="#0BA35E"></path>
          </svg>
        </v-btn>
      </v-row>
    </v-card>

    <!-- 提示框 -->
    <v-dialog v-model="config2.alert" max-width="450px">
      <v-card>
        <v-card-title>{{ config2.alert_type }}</v-card-title>
        <v-card-text>
          {{ config2.alert_text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="config2.alert = !config2.alert">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 微信扫码 -->
    <v-dialog v-model="wechat_dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">微信登录</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- 内容 -->
            <div class="text-body-2">
              您需要完成微信账号的
              <a @click="jump_to_wechat_bind">绑定</a>
              才能使用该功能，
              <br />
              在微信内点击可直接跳转。
            </div>
            <v-img :src="wechat_data.qrcode_url" alt="获取失败" class="mx-auto" max-width="200px"></v-img>
            <div class="text-caption text-center">请使用微信扫描此处二维码</div>
            <!-- 内容 -->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="wx_login"> 关闭 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- telegram 登录 -->
    <v-dialog v-model="captcha_dialog.enabled" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">您需要输入验证码</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- 内容 -->
            <v-row class="mx-1">
              <v-img :src="captcha_dialog.img" max-width="210px" max-height="70px" @click="getCaptchaDialog"></v-img>
              <v-text-field class="ma-2" v-model="captcha_dialog.input" :disabled="disable_input" label="验证码"
                outlined></v-text-field>
            </v-row>
            <!-- 内容 -->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="do_send_email_code" v-if="captcha_dialog.type == 0"
            :loading="send_btn.loading"> 获取验证码 </v-btn>
          <v-btn color="blue darken-1" text @click="get_tg_url" v-if="captcha_dialog.type == 1"> 提交 </v-btn>
          <v-btn color="" text @click="captcha_dialog.enabled = false; captcha_dialog.input = ''"> 取消 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- telegram等待框 -->
    <v-dialog v-model="tg_pending_dialog" max-width="450px" persistent>
      <v-card>
        <v-card-title>Telegram登录</v-card-title>
        <v-card-text>
          等待完成认证。。。(若未跳转 <a :href="tg_url" target="_blank">点击此处</a>)
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import nprogress from "nprogress";
export default {
  name: "login",
  data: function () {
    return {
      email: "",
      password_or_code: 0,
      password: "",
      email_code: "",
      disable_input: false,
      loader: null,
      loading: false,
      config: {
        alert_text: "",
        alert_type: "info",
        alert: false,
      },
      config2: {
        alert_text: "",
        alert_type: "info",
        alert: false,
      },
      callback: "",
      wechat_dialog: false,
      wechat_data: {
        timer: false,
        qrcode_url: "",
        token: "",
      },
      captcha: {
        enabled: false,
        img: "",
        input: "",
      },
      tg_pending_dialog: false,
      tg_check_token: "",
      tg_url: "",
      captcha_dialog: {
        enabled: false,
        type: 0,//0为发送邮箱验证码，1为tg获取url
        img: "",
        input: "",
      },
      tg_timer: null,
      send_btn: {
        count: "",
        disabled: false,
        loading: false,
        text: "获取",
        timer: null
      }
    };
  },
  mounted: function () {
    var _this = this;
    if (this.$route.query.callback != null) {
      this.callback = this.$route.query.callback;
    } else {
      this.callback = "/";
    }
    document.onkeydown = function () {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.submit();
      }
    };
  },
  methods: {
    submit: function () {
      if (!(this.email || this.password)) {
        this.error("请输入邮箱和密码");
        return;
      }
      this.disable_input = true;
      this.axios({
        url: "/api/auth/verify",
        method: "post",
        data: {
          email: this.email,
          password: this.password,
          email_code: this.email_code,
          captcha: this.captcha.input,
        },
        params: {
          callback: this.callback,
        },
      })
        .then((res) => {
          var _this = this;
          if (res.data.status == 0) {
            this.$store.dispatch("userStatus", true);
            localStorage.setItem("Flag", res.data.data.token);
            this.cookie.set("token", res.data.data.token, 60 * 60 * 24 * 7);

            this.success("登录成功");
            document.onkeydown = null;
            if (_this.callback != null) {
              setTimeout(function () {
                _this.$router.push(_this.callback);
              }, 500);
            } else {
              setTimeout(function () {
                _this.$router.push("/");
              }, 500);
            }
          } else {
            switch (res.data.status) {
              case -1004:
                this.getCaptcha();
                this.error(this.parseError(res));
                break;
              case -1007:
                this.getCaptcha();
                this.error("请输入验证码");
                break;
              case -1008:
                this.getCaptcha();
                this.captcha.input = "";
                this.error("验证码有误");
                break;
              default:
                this.error(this.parseError(res));
            }
            this.disable_input = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.error("登录失败:" + err);
          this.disable_input = false;
        });

      const that = this;
      setTimeout(function () {
        if (that.alert) {
          that.config.alert = !that.config.alert;
        }
      }, 2000);
    },
    get_tg_url: function () {
      if (this.captcha_dialog.enabled == true) {
        this.captcha_dialog.enabled = false;
      }
      if (this.tg_timer) {
        clearInterval(this.tg_timer);
        this.wechat_data.timer = null;
      }
      this.axios({
        method: "get",
        url: "/api/telegram/loginUrl",
        params: {
          callback: "/#" + this.$route.query.callback,
          captcha: this.captcha_dialog.input,
        },
      })
        .then((res) => {
          var _this = this;
          if (res.data.status == 0) {
            this.tg_check_token = res.data.data.check_token;
            this.tg_url = res.data.data.url;
            this.tg_pending_dialog = true;
            this.tg_timer = setInterval(function () {
              _this.tg_check();
            }, 3000);
            window.open(this.tg_url, '_blank')
          } else {
            switch (res.data.status) {
              case -1004:
                this.getCaptchaDialog(1);
                this.error(this.parseError(res));
                break;
              case -1007:
                this.getCaptchaDialog(1);
                this.error("请输入验证码");
                break;
              case -1008:
                this.getCaptchaDialog(1);
                this.captcha.input = "";
                this.error("验证码有误");
                break;
              default:
                this.error(this.parseError(res));
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.error("登录失败:" + err);
        });
    },
    tg_check: function () {
      let _this = this;
      console.log("tg_check:" + this.tg_check_token);
      this.axios({
        method: "post",
        url: "/api/telegram/checkLogin",
        data: {
          token: _this.tg_check_token,
        },
      })
        .then(function (res) {
          if (res.data.status < 0) {
            _this.error(_this.parseError(res));
          } else if (res.data.data.login == false) {
            return;
          } else if (res.data.data.login == true) {
            _this.success("登录成功");
            if (_this.callback != null) {
              setTimeout(function () {
                _this.$router.push(_this.callback);
              }, 1000);
            } else {
              setTimeout(function () {
                _this.$router.push("/");
              }, 1000);
            }
          }
          _this.tg_pending_dialog = false;
          clearInterval(_this.tg_timer);
          _this.tg_timer = null;
        })
        .catch(function (error) {
          _this.error(error);
          _this.tg_pending_dialog = false;
        });
    },
    jump_to_wechat_bind: function () {
      this.$router.push("/wechatBind");
      this.callback = "/wechatBind";

      this.success2("请先登录");
      this.getCaptcha();
      this.wx_login();
      nprogress.done();
    },
    getCaptcha: function () {
      this.captcha.input = "";
      this.axios({
        url: "/api/captcha",
        method: "get",
      })
        .then((res) => {
          if (res.data.status == 0) {
            //ok
            this.captcha.enabled = true;
            this.captcha.img = res.data.data.image;
          } else {
            this.error(this.parseError(res));
          }
        })
        .catch((err) => {
          console.log(err);
          this.error("登录失败:" + err);
        });
    },
    getCaptchaDialog: function (type) {
      this.captcha.input = "";
      this.captcha_dialog.type = type;
      this.axios({
        url: "/api/captcha",
        method: "get",
      })
        .then((res) => {
          if (res.data.status == 0) {
            //ok
            this.captcha_dialog.enabled = true;
            this.captcha_dialog.img = res.data.data.image;
          } else {
            this.error(this.parseError(res));
          }
        })
        .catch((err) => {
          console.log(err);
          this.error("登录失败:" + err);
        });
    },
    wx_login: function () {
      if (this.wechat_data.timer) {
        this.wechat_dialog = false;
        clearInterval(this.wechat_data.timer);
        this.wechat_data.timer = null;
        return;
      }
      this.axios({
        method: "get",
        url: "/api/wechat/getLoginQrcode",
        params: {
          callback: "/#" + this.$route.query.callback,
        },
      })
        .then((res) => {
          var _this = this;
          if (res.data.status == 0) {
            if (res.data.data.type == 1) {
              //跳转
              setTimeout(function () {
                window.location.href = res.data.data.redirectUrl;
              }, 1000);
            } else {
              this.wechat_data.qrcode_url = res.data.data.qrcode;
              this.wechat_data.token = res.data.data.checkToken;
              this.wechat_dialog = true;
              this.wechat_data.timer = setInterval(function () {
                _this.wx_login_check();
              }, 3000);
            }
          } else {
            this.error(this.parseError(res));
          }
        })
        .catch((err) => {
          console.log(err);
          this.error("登录失败:" + err);
        });
    },
    wx_login_check: function () {
      let _this = this;
      this.axios({
        method: "post",
        url: "/api/wechat/loginCheck",
        data: {
          checkToken: _this.wechat_data.token,
        },
      })
        .then(function (res) {
          if (res.data.status < 0) {
            _this.error(_this.parseError(res));
          } else if (res.data.status == 0) {
            return;
          } else if (res.data.status == 1) {
            _this.wechat_dialog = false;
            _this.success("登录成功");
            if (_this.callback != null) {
              setTimeout(function () {
                _this.$router.push(_this.callback);
              }, 1000);
            } else {
              setTimeout(function () {
                _this.$router.push("/");
              }, 1000);
            }
          }
          clearInterval(_this.wechat_data.timer);
          _this.wechat_data.timer = null;
        })
        .catch(function (error) {
          _this.error(error);
        });
    },
    switch_password_or_code: function (change_to) {
      this.password_or_code = change_to;
      if(change_to==0){
        this.info("请使用密码登录");
      }else{
        this.info("请使用邮箱验证码登录");
        if(this.captcha.enabled==true){
          this.captcha.enabled = false;
        }
      }
    },
    do_send_email_code: function () {
      var _this = this;
      this.send_btn.loading = true;
      this.axios({
        url: "/api/auth/emailCode",
        method: "post",
        data: {
          captcha: this.captcha_dialog.input,
          email: this.email
        }
      })
        .then((res) => {
          if (res.data.status == 0) {
            //ok
            this.send_btn.count = 60;
            this.send_btn.disabled = true;
            this.count_down();
            this.send_btn.timer = setInterval(function () {
              _this.count_down();
            }, 1000);
            this.success("验证码已发送，请注意查收！");
          } else if (res.data.status == -1007) {
            this.getCaptchaDialog(0);
          } else {
            this.error(this.parseError(res));
          }
          if (this.captcha_dialog.enabled == true) {
            this.captcha_dialog.enabled = false;
            this.captcha_dialog.input = "";
          }
          this.send_btn.loading = false;
        })
        .catch((err) => {
          this.error("失败:" + err);
          this.send_btn.loading = false;
        });
    },
    count_down: function () {
      this.send_btn.count--;
      this.send_btn.text = "重新获取(" + this.send_btn.count + "秒)";
      if (this.send_btn.count < 1) {
        clearInterval(this.send_btn.timer);
        this.send_btn.timer = null;
        this.send_btn.text = "重新获取";
        this.send_btn.disabled = false;
      }
    },
    success: function (text) {
      this.config.alert = !this.config.alert;
      this.config.alert_text = text;
      this.config.alert_type = "success";
    },
    info: function (text) {
      this.config.alert = !this.config.alert;
      this.config.alert_text = text;
      this.config.alert_type = "info";
    },
    success2: function (text) {
      this.config2.alert = !this.config2.alert;
      this.config2.alert_text = text;
      this.config2.alert_type = "提示";
    },
    error: function (err) {
      this.config.alert = !this.config.alert;
      this.config.alert_text = err;
      this.config.alert_type = "error";
    },
    show_loading: function () {
      this.loader = "loading";
    },
  },
  computed:{
    login_btn_disabled:function(){
      if(this.disable_input || this.config.alert){
        return true
      }
      if(this.password_or_code==0&&this.password==""){
        return true
      }
      if(this.password_or_code==1&&this.email_code==""){
        return true
      }
      return false
    }
  }
};
</script>
